import React from "react";
import { Link } from "gatsby";
import { PageIndexProvider, useStackedPage } from "react-stacked-pages-hook";

import "gatsby-theme-garden/src/components/note-wrapper.css";

function noteContainerClassName({ overlay, obstructed, highlighted } = {}) {
  return `note-container ${overlay ? "note-container-overlay" : ""} ${
    obstructed ? "note-container-obstructed" : ""
  } ${highlighted ? "note-container-highlighted" : ""}`;
}

const NoteWrapper = ({ children, slug, title }) => {
  const [, state] = useStackedPage();

  return (
    <div className={noteContainerClassName(state)}>
      <div className="note-content">{children}</div>
      <Link to={slug} className="obstructed-label">
        {title}
      </Link>
    </div>
  );
};

const ContextProvider = ({ i, ...rest }) => (
  <PageIndexProvider value={i}>
    <NoteWrapper {...rest} />
  </PageIndexProvider>
);

export default ContextProvider;
