import React, { memo } from "react";
import { useWindowWidth } from "@react-hook/window-size/throttled";
import {
  useStackedPagesProvider,
  StackedPagesProvider,
} from "react-stacked-pages-hook";
import {
  dataToNote,
  dataToSlug,
} from "gatsby-theme-garden/src/components/../utils/data-to-note";
import Note from "./note";
import NoteWrapper from "./note-wrapper";
import Header from "./header";
import SEO from "gatsby-theme-garden/src/components//seo";
import LastModified from "./last-modified";

import "gatsby-theme-garden/src/components/theme.css";
import "gatsby-theme-garden/src/components/stacked-layout.css";
import "gatsby-theme-garden/src/components/custom.css";

const Content = ({ windowWidth, scrollContainer, stackedPages, index }) => {
  return (
    <div className="layout">
      <SEO title={stackedPages[stackedPages.length - 1].data.title} />
      <Header />
      <div>
        <div className="note-columns-container">
          <NoteWrapper
            key={stackedPages[0].slug}
            i={0}
            slug={stackedPages[0].slug}
            title={stackedPages[0].data.title}
          >
            <Note {...stackedPages[0].data} />
            {stackedPages[0].slug === "/readme" ? (
              <LastModified></LastModified>
            ) : null}
          </NoteWrapper>
        </div>
      </div>
    </div>
  );
};
const MemoContent = memo(Content);

const NotesLayout = ({ location, slug, data }) => {
  const windowWidth = useWindowWidth();

  const [state, scrollContainer] = useStackedPagesProvider({
    firstPage: { slug: dataToSlug(data), data },
    location,
    processPageQuery: dataToNote,
    pageWidth: 625,
  });

  let pages = state.stackedPages;
  let activeIndex;
  if (windowWidth <= 800) {
    const activeSlug = Object.keys(state.stackedPageStates).find(
      (slug) => state.stackedPageStates[slug].active
    );
    activeIndex = state.stackedPages.findIndex(
      (page) => page.slug === activeSlug
    );
    if (activeIndex === -1) {
      activeIndex = state.stackedPages.length - 1;
    }

    pages = [state.stackedPages[activeIndex]];
  }

  return (
    <StackedPagesProvider value={state}>
      <MemoContent
        windowWidth={windowWidth}
        scrollContainer={scrollContainer}
        stackedPages={pages}
        index={activeIndex}
      />
    </StackedPagesProvider>
  );
};

export default NotesLayout;
