import React from "react";
import { graphql, StaticQuery, Link } from "gatsby";

const LastModified = () => (
  <StaticQuery
    query={graphql`
      query LastModifiedQuery {
        allFile(
          sort: { fields: modifiedTime, order: DESC }
          limit: 10
          filter: {
            childMdx: { frontmatter: { private: { eq: false } } }
            fields: { slug: { ne: "/readme" } }
          }
        ) {
          nodes {
            fields {
              slug
              title
            }
            modifiedTime(formatString: "MMMM Do, YYYY")
            children {
              id
            }
          }
        }
      }
    `}
    render={(data) => (
      <div>
        <h2>Last Modified Notes</h2>
        <ul>
          {data.allFile.nodes.map((node) => (
            <li>
              <Link to={node.fields.slug}>
                {node.fields.title} ({node.modifiedTime})
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )}
  />
);

export default LastModified;
