import React from "react";
import { Link } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import * as components from "gatsby-theme-garden/src/components/mdx-components";
import MDXRenderer from "gatsby-theme-garden/src/components/mdx-components/mdx-renderer";
import ReferencesBlock from "gatsby-theme-garden/src/components/references-block";

const Note = (data) => {
  const AnchorTag = (props) => (
    <components.a {...props} references={data.outboundReferences} />
  );

  return (
    <React.Fragment>
      {data.partOf ? (
        <div>
          Part of <Link to={data.partOf.slug}>{data.partOf.title}</Link>
        </div>
      ) : null}
      <MDXProvider components={{ ...components, a: AnchorTag }}>
        <MDXRenderer>{data.mdx}</MDXRenderer>
      </MDXProvider>
      <ReferencesBlock references={data.inboundReferences} />
    </React.Fragment>
  );
};

export default Note;
